import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
import FormControl from '../components/form/FormControl';
import swal from "sweetalert";
import profile from "../dist/webImages/profile.webp"
import camera from "../dist/webImages/camera.svg"
import SubmitButton from '../components/SubmitButton';
import back from "../dist/webImages/back.svg";
import { Link, useNavigate } from 'react-router-dom';
import SkeletonCreateEdit from './SkeletonCreateEdit';
import { MainLanguageContext } from '../context/MainLanguageContext';
import usePost from '../customHooks/usePost';
import { toast } from 'react-toastify';
const CreateReviews = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const { mainLanguage } = useContext(MainLanguageContext);
    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }, []);
    let initialValues = {
        name: "",
        occupation: "",
        review: "",
    }
    const [imgPath, setImgPath] = useState();
    const [imgValue, setiImgValue] = useState("");
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setImgPath(path);
            setiImgValue(file);
        }
    }
    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            setImgPath(path);
            const file = e.dataTransfer.files[0];
            setiImgValue(file);
        }
        else {
            swal("Only use Image", "", "warning");
        }
    };
    const [res, apiMethod] = usePost()
    const requireFeild = ["name"];
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
          name: "Name",
        };
        let checkerRequried = [];
        for (const item in values) {
          if (requireFeild.includes(item) && values[item] === "") {
            checkerRequried.push(requireFeildSwal[item]);
          }
          formdata.append(item, values[item]);
        }
        if (!imgPath) {
            checkerRequried.push("Image");
          }

          if (checkerRequried.length > 0) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: checkerRequried.join(","),
              icon: "error",
              dangerMode: true,
            });
          }
          else {
            formdata.append("image", imgValue ?? "");
            apiMethod(`reviews/review/${mainLanguage}`, formdata)
          }
    }

    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            navigate("/reviews")
            toast.success(message);
          }
        }
      }, [res.data])


    if(loading) return <SkeletonCreateEdit heading={"Create Reviews"} />
    return (
        <div className='CreateReviews pr-10 max-lg:pr-6'>
            <Link to={"/reviews"} className="back flex items-center mb-6 gap-2">
                <img src={back} className='w-[2rem]' alt="" />
                <span className='text-[1.4rem] font-MluvkaBold'>Create Reviews</span>
            </Link>
            <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-3'>
                <div className={`ReviewsModel  transition-all duration-300  bg-white rounded-xl `}>

                    <div className=' overflow-auto modelBox'>
                        <Formik initialValues={initialValues}
                            onSubmit={handleSubmit}>
                            <Form name="myForm">
                                <div className="ReviewsBox p-5 px-12 rounded-xl grid grid-cols-[auto,1fr]  gap-10 max-lg:grid-cols-1 max-lg:gap-2 max-lg:px-5">
                                    <div className='w-[8rem] h-[8rem] relative mt-3' onDrop={handleDrop}   >
                                        <img src={imgPath || profile} className='w-full h-full rounded-3xl object-cover border-2 border-[#C0CCE2]' alt="" />
                                        <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                            <input type="file" onChange={handleFileUpload} className='absolute inset-0 opacity-0 cursor-pointer' />
                                            <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                        </div>
                                    </div>
                                    <div className='form'>
                                        <FormControl name="name" placeholder="Enter Name" className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full" control="input" />
                                        <FormControl name="occupation" placeholder="Enter Occupation" className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full" control="input" />
                                        <FormControl name="review" placeholder="Enter Review" className="outline-none w-full h-[12rem] border border-[#CFD5E2] px-5 py-3 resize-none rounded-2xl " control="textarea" />
                                        <ul className='list gap-4  my-3 pb-6'>
                                            <li className=''>
                                                {
                                                    <SubmitButton
                                                        props={{
                                                            class: "btn bg-secondary text-white  uppercase   py-3 px-20 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                                                            text: "Add",
                                                        }}
                                                        buttonLoading={res.isLoading}
                                                    />
                                                }
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateReviews
