import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import FormControl from '../../components/form/FormControl'
import SubmitButton from '../../components/SubmitButton'
import SkeletonContactUs from './SkeletonContactUs'
import { MainLanguageContext } from '../../context/MainLanguageContext'
import useGet from '../../customHooks/useGet'
import usePost from '../../customHooks/usePost'
import swal from "sweetalert";
import { toast } from 'react-toastify'

const ContactUs = () => {
  const { mainLanguage } = useContext(MainLanguageContext);
  const [loading, setLoading] = useState(true)
  const [datas, setDatas] = useState({})
  const [resget, apiMethodGet] = useGet()
  useEffect(() => {
    if (mainLanguage) {
      setLoading(true);
      apiMethodGet(`webContents/contact/${mainLanguage}`);
    }
  }, [mainLanguage]);

  useEffect(() => {
    if (!resget.isLoading) {
      setLoading(false)
      setDatas(resget?.data?.data)
    }
  }, [resget.data])


  const [res, apiMethod] = usePost()
  const requireFeild = ["meta_tag","meta_description","sec_one_heading","sec_two_sub_head_one","sec_two_location_one","sec_two_phone_one","sec_two_email_one","sec_two_url_one","sec_two_sub_head_two","sec_two_location_two","sec_two_phone_two","sec_two_email_two","sec_two_url_two","sec_two_sub_head_three","sec_two_location_three","sec_two_phone_three","sec_two_email_three","sec_two_url_three"]
  const handleSubmit = (values) => {
    let formdata = new FormData();
    let requireFeildSwal = {
      meta_tag:"Meta Title",
      meta_description:"Meta Description",
      sec_one_heading: "sec one heading",
      sec_two_sub_head_one:"sec two sub head one",
      sec_two_location_one:"sec two location one",
      sec_two_phone_one:"sec two phone one",
      sec_two_email_one:"sec two email one",
      sec_two_url_one:"sec two url one",
      sec_two_sub_head_two:"sec two sub head two",
      sec_two_location_two:"sec two location two",
      sec_two_phone_two:"sec two phone two",
      sec_two_email_two:"sec two email two",
      sec_two_url_two:"sec two url two",
      sec_two_sub_head_three:"sec two sub head three",
      sec_two_location_three:"sec two location three",
      sec_two_phone_three:"sec two phone three",
      sec_two_email_three:"sec two email three",
      sec_two_url_three:"sec two url three",
    };
    let checkerRequried = [];
    for (const item in values) {
      if (requireFeild.includes(item) && values[item] === "") {
        checkerRequried.push(requireFeildSwal[item]);
      }
      formdata.append(item, values[item]);
    }
    
    if (checkerRequried.length > 0) {
      swal({
          title: "Required Fields are empty! Please fill and try again",
          text: checkerRequried.join(","),
          icon: "error",
          dangerMode: true,
      });
  }

  else {
    apiMethod(`webContents/contact/${mainLanguage}`, formdata)
  }
  }

  useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        toast.success(message);
      }
    }
  }, [res.data])


  if (resget.isLoading) return <SkeletonContactUs />
const dd = resget?.data?.data
  const initialValues = {
    meta_tag: dd?.meta_tag,
    meta_description: dd?.meta_description,
    schema_code: dd?.schema_code,
    schema_code: dd?.schema_code,
    sec_one_heading: dd?.sec_one_heading,
    sec_two_heading: dd?.sec_two_heading,
    sec_two_sub_head_one: dd?.sec_two_sub_head_one,
    sec_two_location_one: dd?.sec_two_location_one,
    sec_two_phone_one: dd?.sec_two_phone_one,
    sec_two_email_one: dd?.sec_two_email_one,
    sec_two_url_one: dd?.sec_two_url_one,
    sec_two_sub_head_two: dd?.sec_two_sub_head_two,
    sec_two_location_two: dd?.sec_two_location_two,
    sec_two_phone_two: dd?.sec_two_phone_two,
    sec_two_email_two: dd?.sec_two_email_two,
    sec_two_url_two: dd?.sec_two_url_two,
    sec_two_sub_head_three: dd?.sec_two_sub_head_three,
    sec_two_location_three: dd?.sec_two_location_three,
    sec_two_phone_three: dd?.sec_two_phone_three,
    sec_two_email_three: dd?.sec_two_email_three,
    sec_two_url_three: dd?.sec_two_url_three,
  }
  return (
    <div className='contactUsPage pr-10 max-lg:pr-6'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} >
        <Form>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
              <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
            </div>
          </div>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 1</div>
            <FormControl name="sec_one_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
          </div>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 2</div>
            <FormControl name="sec_two_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
            <div className="section2Main mt-3 grid grid-cols-3 gap-3 max-lg:grid-cols-1">
              <div className="section2MainBox bg-[#D4DEF1] p-4 rounded-2xl">
                <FormControl name="sec_two_sub_head_one" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_location_one" label={"Location"} placeholder="Enter Location" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                <FormControl type="phone" name="sec_two_phone_one" label={"Phone"} placeholder="Enter Phone" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl type="email" name="sec_two_email_one" label={"Email"} placeholder="Enter Email" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_url_one" label={"Location URL"} placeholder="Enter Location URL" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              </div>
              <div className="section2MainBox bg-[#D4DEF1] p-4 rounded-2xl">
                <FormControl name="sec_two_sub_head_two" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_location_two" label={"Location"} placeholder="Enter Location" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                <FormControl type="phone" name="sec_two_phone_two" label={"Phone"} placeholder="Enter Phone" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl type="email" name="sec_two_email_two" label={"Email"} placeholder="Enter Email" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_url_two" label={"Location URL"} placeholder="Enter Location URL" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              </div>
              <div className="section2MainBox bg-[#D4DEF1] p-4 rounded-2xl">
                <FormControl name="sec_two_sub_head_three" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_location_three" label={"Location"} placeholder="Enter Location" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                <FormControl type="phone" name="sec_two_phone_three" label={"Phone"} placeholder="Enter Phone" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl type="email" name="sec_two_email_three" label={"Email"} placeholder="Enter Email" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="sec_two_url_three" label={"Location URL"} placeholder="Enter Location URL" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
              </div>
            </div>

          </div>
          <SubmitButton
            props={{
              class: "btn bg-secondary text-white px-12 ml-auto uppercase mb-3   py-3 rounded-full w-100 block mt-5 submit hover:bg-primary transition-all duration-300",
              text: "Submit",
            }}
            buttonLoading={res.isLoading}

          
          />
        </Form>
      </Formik>

    </div>
  )
}

export default ContactUs
