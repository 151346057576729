import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonSetting = () => {
  return (
    <SkeletonTheme baseColor="#fff" highlightColor="#f0f0f0">
      <div className='Setting pr-10'>
        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
          <Skeleton height={30} width={150} className="mb-4" />
          <div className='flex items-start gap-5'>
            <div>
              <Skeleton height={15} width={100} className="mb-1" />
              <Skeleton height={64} width={64} className='rounded-3xl mb-4' />
            </div>
            <Skeleton height={48} className="w-full rounded-xl" />
            <Skeleton height={48} width={150} className="rounded-full" />
          </div>
        </div>
        
        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
          <div className='flex justify-between items-center mb-3'>
            <Skeleton height={30} width={200} className="mb-4" />
            <Skeleton height={32} width={150} className='rounded-full' />
          </div>
          <div className='grid grid-cols-2 gap-4'>
            {Array(2).fill().map((_, index) => (
              <div className='grid relative grid-cols-[auto,1fr] gap-3' key={index}>
                <div>
                  <Skeleton height={15} width={100} className="mb-1" />
                  <Skeleton height={64} width={64} className='rounded-3xl mb-4' />
                </div>
                <Skeleton height={64} className="w-full rounded-xl" />
              </div>
            ))}
          </div>
          <Skeleton height={48} width={150} className="rounded-full mt-8" />
        </div>

        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
          <Skeleton height={30} width={200} className="mb-4" />
          <div className='grid grid-cols-[1fr,1fr,auto] gap-3'>
            <Skeleton height={48} className="w-full rounded-xl" />
            <Skeleton height={48} className="w-full rounded-xl" />
            <Skeleton height={48} width={150} className="rounded-full" />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonSetting;
