import React, { useEffect } from 'react'
import {  useNavigate } from 'react-router-dom'
import MainEditValuesProvider from './context/MainEditValuesContext'
import MainMenuActiveProvider from './context/MainMenuActiveContext'
import { getTokenSession } from './utils/common'
import config from "./services/config.json";
import MainLanguageProvider from './context/MainLanguageContext'
import DashboardInner from './DashboardInner'
import MainBookingProvider from './context/MainBooking'

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if(!getTokenSession()) {
      navigate(`/${config.demo}login`)
    }
  }, [navigate])

  return (
    <MainBookingProvider>    
    <MainLanguageProvider>    
    <MainMenuActiveProvider>    
    <MainEditValuesProvider>    
     <DashboardInner />
    </MainEditValuesProvider>
    </MainMenuActiveProvider>
    </MainLanguageProvider>
    </MainBookingProvider>    

  )
}

export default Dashboard





