import React, { useContext, useEffect, useState } from 'react';
import back from "../dist/webImages/back.svg";
import { Form, Formik } from 'formik';
import FormControl from '../components/form/FormControl';
import SubmitButton from '../components/SubmitButton';
import CKEditors from '../components/form/CKEditors';
import UploadProduct from '../components/UploadProduct';
import { Link, useNavigate } from 'react-router-dom';
import SkeletonCreateEdit from './SkeletonCreateEdit';
import usePost from '../customHooks/usePost';
import swal from "sweetalert";
import { MainLanguageContext } from '../context/MainLanguageContext';
import { toast } from 'react-toastify';

const CreateEvent = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { mainLanguage } = useContext(MainLanguageContext);
    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }, []);
    const [datas, setDatas] = useState({
        "description": "",
        "author_details": ""
    });
    const [fileList, setFileList] = useState([
    ]);
    const { description, author_details } = datas;
    let initialValues = {
        meta_tag: "",
        meta_description: "",
        scheme_code: "",
        title: "",
        author_name: "" ,
        date: "" ,
    };

    // const handlediscriptionChange = (event, editor) => {
    //     const data = editor.getData();
    //     setDatas(d => ({ ...d, "description": data }));
    // };
    const handlediscriptionChange = (e) => {
        setDatas(d => ({ ...d, "description": e }));
    };
    const handleauthor_detailsChange = (e) => {
        setDatas({ ...datas, "author_details": e });
    };
    const hanldefileUpdate = (values) => {
        setFileList(values)
    }
    const [res, apiMethod] = usePost()
    const requireFeild = ["meta_tag","meta_description","title","author_name","date"];
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
          meta_tag: "Meta Tag",
          meta_description: "Meta Description",
          title: "Title",
          date: "date",
          author_name: "Author Name",
        };
        let checkerRequried = [];
        for (const item in values) {
            console.log(requireFeild.includes(item),requireFeildSwal[item],item)
          if (requireFeild.includes(item) && !values[item]) {
            checkerRequried.push(requireFeildSwal[item]);
          }
        }
        formdata.append("meta_tag", values["meta_tag"]);
        formdata.append("meta_description", values["meta_description"]);
        formdata.append("scheme_code", values["scheme_code"]);
        formdata.append("author_name", values["author_name"]);
        formdata.append("date", values["date"]);
        formdata.append("title", values["title"]);
        formdata.append("name", values["title"]);
    
        if (!fileList.length) {
            checkerRequried.push("News Image");
          }
          if (checkerRequried.length > 0) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: checkerRequried.join(","),
              icon: "error",
              dangerMode: true,
            });
          }
          else {
            for (let index = 0; index < fileList.length; index++) {
                console.log(fileList[index])
                formdata.append("images[]", fileList[index]?.originFileObj ?? "");
              }
              formdata.append("description", description);
              formdata.append("author_details", author_details);
            apiMethod(`events/create/${mainLanguage}`, formdata)
          }
    };

    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            navigate("/events")
            toast.success(message);
          }
        }
      }, [res.data])
    if(loading) return <SkeletonCreateEdit heading={"Create Events"} />

    return (
        <div className='newscreate pr-10 max-lg:pr-6'>
            <Link to={"/events"} className="back flex items-center mb-6 gap-2">
            <img src={back} className='w-[2rem]' alt="" />
            <span className='text-[1.4rem] font-MluvkaBold'>Create Events</span>
            </Link>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form name="myForm">
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-3'>
                    <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
                    <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                    <FormControl name="scheme_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                  </div>
                        <div className=''>
                            <UploadProduct updatess={(item) => hanldefileUpdate(item)} filelis={fileList} />
                            <div className='form mt-2'>
                                <div className='grid grid-cols-2 gap-2 max-lg:grid-cols-1 max-lg:gap-0'>
                                </div>
                                    <FormControl type="date" name="date" className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full" control="input" />
                                    <FormControl name="title" placeholder="Enter Event Title" className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full" control="input" />
                                    <div className='longhight'>
                                    <CKEditors label={"discription"} data={description} update={handlediscriptionChange} />
                                    </div>
                                    <FormControl name="author_name" placeholder="Enter Author Name" className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full" control="input" />
                                    <CKEditors label={"Autor Details"} data={author_details} update={handleauthor_detailsChange} />
                            </div>
                        </div>
                        <ul className='list gap-4 mt-10 pb-6 '>
                            <li>
                                {(
                                    <SubmitButton
                                        props={{
                                            class: "btn bg-secondary text-white uppercase py-3 px-10 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                                            text: "Add",
                                        }}
                                        buttonLoading={res.isLoading}
                                    />
                                )}
                            </li>
                        </ul>

                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default CreateEvent
