import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import SubmitButton from '../components/SubmitButton'
import { MainLanguageContext } from '../context/MainLanguageContext'
import usePost from '../customHooks/usePost'
import { toast } from 'react-toastify'
import swal from "sweetalert";
import { Link, useNavigate, useParams } from 'react-router-dom'
import back from "../dist/webImages/back.svg";
import SkeletonCreateEdit from './SkeletonCreateEdit'
import useGet from '../customHooks/useGet'

const EditCategory = () => {
  const navigate = useNavigate();
  const {id} = useParams();
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [loading, setLoading] = useState(true)
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
          setLoading(true);
          apiMethodGet(`servicescategory/service_categories/${mainLanguage}/${id}`);
        }
      }, [mainLanguage]);
    useEffect(() => {
        if (resget?.data) {
          setLoading(false);
        }
      }, [resget?.data]);


       const [res, apiMethod] = usePost()
       const requireFeild = ["category_title"];
    const handleSubmit = (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
          category_title: "category_title",
          };
          let checkerRequried = [];
        for (const item in values) {
            if (requireFeild.includes(item) && values[item] === "") {
              checkerRequried.push(requireFeildSwal[item]);
            }
            formdata.append(item, values[item]);
        }


          if (checkerRequried.length > 0) {
            swal({
                title: "Required Fields are empty! Please fill and try again",
                text: checkerRequried.join(","),
                icon: "error",
                dangerMode: true,
            });
        }

        else {
          apiMethod(`services_category/servicecategories/${mainLanguage}/${id}`, formdata)
        }
    }

    useEffect(() => {
        if (res.data) {
          const { status, data } = res?.data
          if (status === "false") {
            toast.error(data);
          }
          else {
            toast.success(data);
            navigate("/service/category");
          }
        }
      }, [res.data])


    if (loading) return <SkeletonCreateEdit />
    const initialValues = {
      category_title: resget?.data?.data?.category_title,
    }
    return (
        <div className='PrivicyPolicy pr-10'>
               <Link to={"/service/category"} className="back flex items-center mb-5 gap-2">
            <img src={back} className='w-[2rem]' alt="" />
            <span className='text-[1.4rem] font-MluvkaBold'>Edit Category</span>
            </Link>
        <Formik initialValues={initialValues}  onSubmit={handleSubmit}  >
            <Form>
                <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-8'>
                    <FormControl name="category_title" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                </div>
                {(
                    <SubmitButton
                        props={{
                            class: "btn bg-secondary text-white uppercase py-3 px-10 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                            text: "Update",
                        }}
                        buttonLoading={res.isLoading}
                    />
                )}
                <br />
            </Form>
        </Formik>
    </div>
    )
}

export default EditCategory