import React, { useContext, useEffect, useState } from 'react'
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import { Table } from 'antd';

const Quote = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [datas, setDatas] = useState();
    const [resget, apiMethodGet] = useGet();
    useEffect(() => {
        if (mainLanguage) {
            apiMethodGet(`quote/${mainLanguage}`);
        }
    }, [mainLanguage]);
    useEffect(() => {
        if (!resget.isLoading) {
            const updatedData = resget?.data?.data?.map((item, index) => ({
                ...item,
                key: `item-${index}`, // Add a unique key to each object
            }));
            setDatas(updatedData);
        }
    }, [resget.data]);
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
        },
      ];

if(!datas) return '';
  return (
    <div className='pr-10'>
        <h2 className='font-Mluvka text-[1.938rem] max-lg:text-[1.1rem] mb-2'>Quote</h2>
    <Table dataSource={datas} columns={columns} />
    </div>
  )
}

export default Quote