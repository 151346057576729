import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MainMenuActiveContext } from '../context/MainMenuActiveContext';
import { FaUserSecret, FaUserCircle, FaListOl, FaPuzzlePiece, FaRegCalendarAlt, FaUserAlt,FaMoneyBillAlt, FaHandshake,FaFolder, FaSuitcase, FaRegNewspaper, FaQuestion} from "react-icons/fa";
import { Menu } from 'antd';
import { MdDashboard, MdEmojiEvents, MdOutlineAppSettingsAlt, MdReviews } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { RiTeamFill } from "react-icons/ri";
import { FaBuildingUser } from "react-icons/fa6";
import { GrBusinessService, GrGallery } from "react-icons/gr";
import { LiaElementor } from "react-icons/lia";
import { CgWebsite } from "react-icons/cg";


const links = {
    "/": "sub1",
    "/booking": "sub2", // Add '/' before 'booking' to match the pathname correctly
    "/case/create": "sub3",
    "/case/edit": "sub3",
    "/case": "sub4",
    "/case/update": "sub5",
    "/case/inquires": "sub6",
    "/team": "sub7",
    "/team/create": "sub8",
    "/team/edit": "sub8",
    "/departments": "sub8",
    "/departments/create": "sub8",
    "/departments/edit": "sub8",
    "/services": "sub9",
    "/services/create": "sub9",
    "/services/edit": "sub9",
    "/service/category": "sub10",
    "/service/category/create": "sub10",
    "/service/category/edit": "sub10",
    "/news": "sub11",
    "/news/create": "sub11",
    "/news/edit": "sub11",
    "/events": "sub12",
    "/events/create": "sub12",
    "/events/edit": "sub12",
    "/gallery": "sub13",
    "/reviews": "sub14",
    "/reviews/create": "sub14",
    "/reviews/edit": "sub14",
    "/faqs": "sub15",
    "/elements": "sub16",
    "/webcontent": "sub17",
    "/appcontent": "sub18",
    "/legalsecretary": "sub21",
};

function getItem(label, key, icon, children, type) {
    return {key, icon,children,label,type};
  
  }

const LeftSide = () => {
    const location = useLocation();
    const [activeDropdown, setActiveDropdown] = useState()
    let abc = []
    if(location.pathname.includes("case") || location.pathname.includes("case/update") || location.pathname.includes("case/inquires")) {
        abc = ["sub3"]
    }
    else if(location.pathname.includes("services") || location.pathname.includes("service/category")) {
        abc = ["sub19"]
    }
    else {
        abc = []
    }
    const [openKeys, setOpenKeys] = useState(abc);
     let a = location.pathname.split("/")
     a.pop()
     let pathsplit = location.pathname.includes("edit") ? a.join("/") : location.pathname;
    const [selectedKey, setSelectedKey] = useState([links[pathsplit]])
    const rootSubmenuKeys = ['sub1','sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9', 'sub10', 'sub11', 'sub12', 'sub13', 'sub14', 'sub15', 'sub16', 'sub17',"sub18","sub19","sub20","sub21","sub22"];
    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) == -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) == -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    };

let Dashboard =   getItem(<Link to={`/`}>Dashboard</Link>, 'sub1',<MdDashboard />)
let Booking =   getItem(<Link to={`/booking`}>Booking</Link>, 'sub2',<BiSolidPurchaseTag />)
let CaseManagement =   getItem("Case Management", 'sub3',<FaSuitcase />,[
    getItem(<Link to={`/case`}>Case List</Link>, 'sub4'),
    getItem(<Link to={`/case/update`}>Case Update</Link>, 'sub5'),
    getItem(<Link to={`/case/inquires`}>Case Inquires</Link>, 'sub6'),
  ])

  let Team =   getItem(<Link to={`/team`}>Team</Link>, 'sub7',<RiTeamFill />)
  let LegalSecretary =   getItem(<Link to={`/legalsecretary`}>Legal Secretary</Link>, 'sub21',<RiTeamFill />)
  let Departments =   getItem(<Link to={`/departments`}>Departments</Link>, 'sub9',<FaBuildingUser />)
  let Services =   getItem("Services", 'sub22',<GrBusinessService />,[
    getItem(<Link to={`/services`}>List</Link>, 'sub10'),
    getItem(<Link to={`/service/category`}>Category</Link>, 'sub11'),
  ])
  let news =   getItem(<Link to={`/news`}>News</Link>, 'sub12',<FaRegNewspaper />)
  let Events =   getItem(<Link to={`/events`}>Events</Link>, 'sub13',<MdEmojiEvents />)
  let Gallery =   getItem(<Link to={`/gallery`}>Gallery</Link>, 'sub14',<GrGallery />)
  let Reviews =   getItem(<Link to={`/reviews`}>Reviews</Link>, 'sub15',<MdReviews />)
  let faqs =   getItem(<Link to={`/faqs`}>Mobile FAQs</Link>, 'sub16',<FaQuestion />)
  let elements =   getItem(<Link to={`/elements`}>Elements</Link>, 'sub17',<LiaElementor />)
  let WebContent =   getItem(<Link to={`/webcontent`}>WebContent</Link>, 'sub18',<CgWebsite />)
  let APPContent =   getItem(<Link to={`/appcontent`}>APP Content</Link>, 'sub19',<MdOutlineAppSettingsAlt />)

    const items = [
        Dashboard,
        Booking,
        CaseManagement,
        Team,
        LegalSecretary,
        Departments,
        Services,
        news,
        Events,
        Gallery,
        Reviews,
        faqs,
        elements,
        WebContent,
        APPContent,
      ];
    

    

    const { getActive,handleGetEditValue } = useContext(MainMenuActiveContext);
    return (
        <div className={`MainDashboard__left w-[20%] max-lg:absolute max-lg:w-full max-lg:bg-white max-lg:z-50 max-lg:h-svh transition-all duration-300  py-5 pl-4 ${getActive ? "active" : ""}`}>
            <div className='closeButton hidden max-lg:grid cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem]  place-items-center rounded-[.7rem] z-10' onClick={() => handleGetEditValue(false)}>
                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="logo pb-11 px-6">
                <Link to={"/"} onClick={() => handleGetEditValue(false)}>
                <img src={require("../dist/webImages/logo.png")} className='w-[10rem] mx-auto max-lg:m-0' alt="" />
                </Link>
            </div>
            <nav className='nav max-h-[70vh] overflow-y-auto pr-4'>
            <Menu
              defaultSelectedKeys={selectedKey}
              defaultOpenKeys={["sub19"]}
              mode="inline"
              theme="light"
              items={items}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              />
                {/* <ul className='list'>
                    {menu.map((item, index) => {
                        const { link, label,dropdown,id } = item;
                        const isActive = link === '/'
                            ? location.pathname === '/'
                            : links[link]?.some(l => location.pathname.startsWith(l));
                        return (
                            <li key={index} className={`my-[.2rem] dropwMenu ${id === activeDropdown ? "active" : ""}  ${isActive && "activesss"}`}>
                              {dropdown ? <div className='flex justify-between dropwMenuI'>  <span onClick={() => handleGetEditValue(false)} className={`font-Mluvka border-2 relative transition-all duration-300 border-transparent rounded-full block py-2 px-8 text-[1.063rem] ${isActive && "active"}`} to={link}>{label}</span>
                               <img className='cursor-pointer dropwMenuimg' onClick={() => handleActiveDropdown(id)} src={img1} alt="" /> </div>
                            :
                            <Link   onClick={() => { handleGetEditValue(false); handleActiveDropdown(id);  }}  className={`font-Mluvka border-2 relative transition-all duration-300 border-transparent rounded-full block py-2 px-8 text-[1.063rem] ${isActive && "active"}`} to={link}>{label}</Link>
                            }
                               {dropdown && <ul className='list pl-4 bg-[#ddd]'>
                                {dropdown.map((item4,index2) => {
                                    return (
                                        <li key={index2}>
                                            <Link onClick={() => handleGetEditValue(false)} className={`font-Mluvka  relative transition-all duration-300  rounded-full block py-1 px-8 text-[1rem] `} to={item4?.link}>{item4?.label}</Link>
                                            </li>
                                    )
                                })}
                                </ul>}
                            </li>
                        );
                    })}
                </ul> */}
            </nav>
            <div className='mt-8'><Link onClick={() => handleGetEditValue(false)} to={"/setting"} className={`font-Mluvka text-[1.063rem] px-8 ${location.pathname.includes("setting") ? "text-secondary" : ""}`}>Settings</Link></div>
        </div>
    )
}

export default LeftSide



