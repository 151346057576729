import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import noimg from '../dist/webImages/no_image.jpg'
import useDelete from '../customHooks/useDelete';
import { toast } from 'react-toastify';
import { StringConvert } from '../components/StringConvert';


const truncateText = (text, maxLength) => {
    if(text) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };


const DepartmentsCard = ({ data, page,alldata,deleted}) => {
    const { department_id,description, team_count, title, department_image,teams } = data
    const [resDelete, apiMethodDelete] = useDelete()
    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    swal("Successfully Delete", "", "success");
                    apiMethodDelete(`departments/${department_id}`)
                }

            });
    }
    useEffect(() => {
        if(resDelete.data) {
          const {message} = resDelete.data
          const update = alldata.filter((item) => item.department_id !== department_id)
          deleted(update)
          toast.success(message);
        }
      }, [resDelete.data])

      
      const maxLength = 200;
      const truncatedText = truncateText(description, maxLength);



    return (
        <div className='dpartCard relative bg-[#fff] border border-[#D4DEF1] rounded-3xl shadow-custom p-4'>
            {page !== "home" &&
                <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10' onClick={handleDelete}>
                    <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>}
            <Link to={`/departments/edit/${department_id}`} className='cursor-pointer'>
                <div className="dpartCard_ grid grid-cols-[4fr,6fr] gap-5 items-center">
                    <div className="dpartCard__img h-[10rem]">
                        <img className='w-full h-full rounded-3xl object-cover' src={department_image} onError={(e) => { e.target.src = noimg }} alt="" />
                    </div>
                    <div className="dpartCard__txt ">
                        {<div className={`h3 font-Mluvka  leading-[1.1] mb-1 `} style={{ fontSize: `${page !== "home" ? "1.6rem" : "1.1rem"}` }}>{title}</div>}

                        <div className="h4 font-Mluvka text-primary"><b className='font-MluvkaBold'>{team_count}</b> Lawyers</div>

                        <div className="dpartCardProfile flex mt-1">
                            {Array.isArray(teams) && teams.slice(0, 5).map((item, index) => {
                                const {lowyer_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={lowyer_image} alt={id} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {
                    page !== "home" &&
                    <div className='text-[.8rem] text-[#3C3E56] leading-[1.5] my-2'>{StringConvert(truncatedText)}</div>
                }
            </Link>
        </div>
    )
}

export default DepartmentsCard
