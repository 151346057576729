import React, { useContext, useEffect, useState } from 'react'
import plus from '../dist/webImages/plus.svg'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import SkeletonServices from './SkeletonServices';
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import useDelete from '../customHooks/useDelete';
import { toast } from 'react-toastify';
import { Pagination } from 'antd';
import usePost from '../customHooks/usePost';
const Services = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [datas, setDatas] = useState()
    const [resget, apiMethodGet] = useGet()
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationn, setPaginationn] = useState(8);
    const [searchValue, setSearchValue] = useState("")

    const onChange = (current, pageSize) => {
        setCurrentPage(current)
        let formdata = new FormData();
        formdata.append('search_query', searchValue);
        if ((searchValue).trim()) {
            apiMethod2(`searchlist/search_service_list/${mainLanguage}/8?page=${current}`, formdata);
        } else {
            apiMethodGet(`services/${mainLanguage}/8?page=${current}`, formdata);
        }
    };


    useEffect(() => {
        if (mainLanguage) {
            setCurrentPage(1)
            apiMethodGet(`services/${mainLanguage}/8?page=1`);
        }
    }, [mainLanguage]);
    useEffect(() => {
        if (!resget.isLoading) {
            setDatas(resget?.data?.data)
            setPaginationn(resget.data?.pagination)
        }

    }, [resget.data])

    const [res2, apiMethod2] = usePost()
    let debounceTimer;
    const debounce = (func, delay) => {
        return (...args) => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
    
    
      const executeApiCall = (e) => {
        setSearchValue(e.target.value)
        let formdata = new FormData();
        formdata.append('search_query', e.target.value);
        if ((e.target.value).trim()) {
            apiMethod2(`searchlist/search_service_list/${mainLanguage}/8?page=${currentPage}`, formdata);
        } else {
            apiMethodGet(`services/${mainLanguage}/8?page=${currentPage}`, formdata);
        }
      };
    
      const handleChange = debounce(executeApiCall, 1000)



    useEffect(() => {
        setDatas([])
        if (res2.data) {
            setDatas(res2?.data?.data);
            setPaginationn(res2?.data?.pagination)
        }
    }, [res2.data]);





    const [resDelete, apiMethodDelete] = useDelete()
    const handleDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    swal("Successfully Delete", "", "success");
                    apiMethodDelete(`services/${id}`)
                }

            });
    }
    useEffect(() => {
        if (resDelete.data) {
            const { message } = resDelete.data
            setCurrentPage(1)
            apiMethodGet(`services/${mainLanguage}/8?page=1`);
            toast.success(message);
        }
    }, [resDelete.data])
    if (resget.isLoading) return <SkeletonServices />
    return (
        <div className='services pr-10 max-lg:pr-6'>
            <div className="servicesTop flex justify-between items-center mb-4">
                <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'> Services</h6>
                <div className='flex gap-1'>
                    <div className="inputBox w-[16rem] max-lg:hidden">
                        <input type="text" onChange={handleChange} className='w-full border h-[2.8rem] rounded-full px-4 border-[#ddd] outline-none' placeholder='Search' />
                    </div>
                    <Link to={"/services/create"} className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' >
                        <img src={plus} alt="plus" />
                        <span className='font-MluvkaBold text-secondary capitalize'>Add Services</span>
                    </Link>
                </div>
            </div>
            <div className="servicesBottom grid grid-cols-4 gap-4 max-lg:grid-cols-1">
                {
                    Array.isArray(datas) && datas.map((item) => {
                        const { sec_one_image, sec_one_heading_one, id } = item
                        return (
                            <div className=' border-2 border-[#ddd] p-3 rounded-2xl relative' key={id}>
                                <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[-1rem] right-[-1rem] grid place-items-center rounded-[.7rem] z-10' onClick={() => handleDelete(id)}>
                                    <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <Link to={`/services/edit/${id}`} className="servicesCard relative" >

                                    <div className="servicesCard__img">
                                        <img src={sec_one_image} className='w-[100%] h-[13rem] object-cover' alt="" />
                                    </div>
                                    <div className="servicesCard__Body">
                                        <div className="h4 font-MluvkaBold text-[1.4rem] mt-3">{sec_one_heading_one}</div>
                                    </div>
                                </Link>
                            </div>

                        )
                    })
                }

            </div>
            {!resget.isLoading && <div className='my-4'>
                <Pagination
                    onChange={onChange}
                    defaultCurrent={currentPage}
                    total={paginationn?.total}
                    pageSize={8}
                />
            </div>}
        </div>
    )
}

export default Services