import React, { useContext, useEffect, useState } from 'react'
import DepartmentsCard from './DepartmentsCard';
import plus from '../dist/webImages/plus.svg'
import SkeletonHeading from '../components/SkeletonHeading';
import SkeletonDepartmentsCard from './SkeletonDepartmentsCard';
import { Link } from 'react-router-dom';
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import { Pagination } from 'antd';
import usePost from '../customHooks/usePost';




const Departments = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [resget, apiMethodGet] = useGet()
    const [datas, setDatas] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationn, setPaginationn] = useState(8);
    const [searchValue, setSearchValue] = useState("")

    const onChange = (current, pageSize) => {
        setCurrentPage(current)
        let formdata = new FormData();
        formdata.append('search_query', searchValue);
        if ((searchValue).trim()) {
            apiMethod2(`searchlist/search_department_list/${mainLanguage}/6?page=${current}`, formdata);
        } else {
            apiMethodGet(`departments/${mainLanguage}/6?page=${current}`, formdata);
        }
    };

    useEffect(() => {
        if (mainLanguage) {
            setCurrentPage(1)
            apiMethodGet(`departments/${mainLanguage}/6?page=1`);
        }
    }, [mainLanguage]);

    useEffect(() => {
        if (!resget.isLoading) {
            setDatas(resget?.data?.data)
            setPaginationn(resget.data?.pagination)
        }
    }, [resget.data])


    const [res2, apiMethod2] = usePost()
    let debounceTimer;
    const debounce = (func, delay) => {
        return (...args) => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
    
    
      const executeApiCall = (e) => {
        setSearchValue(e.target.value)
        let formdata = new FormData();
        formdata.append('search_query', e.target.value);
        if ((e.target.value).trim()) {
            apiMethod2(`searchlist/search_department_list/${mainLanguage}/6?page=${currentPage}`, formdata);
        } else {
            apiMethodGet(`departments/${mainLanguage}/6?page=${currentPage}`, formdata);
        }
      };
    
      const handleChange = debounce(executeApiCall, 1000)




    useEffect(() => {
        setDatas([])
        if (res2.data) {
            setDatas(res2?.data?.data);
            setPaginationn(res2?.data?.pagination)
        }
    }, [res2.data]);

    return (
        <div className='DepartmentsPage pr-10 max-lg:pr-6'>
            <div className="DepartmentsPageTop flex justify-between items-center">
                {resget.isLoading ? <SkeletonHeading /> : <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'><span>{resget.data?.pagination?.total}</span> Departments</h6>}
                <div className='flex gap-1'>
                    <div className="inputBox w-[16rem] max-lg:hidden">
                        <input type="text" onChange={handleChange} className='w-full border h-[2.8rem] rounded-full px-4 border-[#ddd] outline-none' placeholder='Search' />
                    </div>
                    <Link to={"/departments/create"} className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' >
                        <img src={plus} alt="plus" />
                        <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                    </Link>
                </div>
            </div>
            <div className="DepartmentsPageGrid mt-4 bg-[#EFF4FD] rounded-3xl p-6 grid grid-cols-3 gap-3 max-lg:grid-cols-1 max-lg:p-3">
                {resget.isLoading ?
                    Array.from({ length: 8 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <SkeletonDepartmentsCard />
                        </React.Fragment>
                    ))
                    :
                    Array.isArray(datas) && datas.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <DepartmentsCard data={item} page={"departments"} alldata={datas} deleted={setDatas} />
                            </React.Fragment>
                        )
                    })}
            </div>
            <div className='my-4'>
                <Pagination
                    onChange={onChange}
                    defaultCurrent={currentPage}
                    total={paginationn?.total}
                    pageSize={6}
                />
            </div>
        </div>
    )
}

export default Departments
