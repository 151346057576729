import React, { useContext, useEffect, useState } from 'react'
import close from "../dist/webImages/close.svg"
import { MainLanguageContext } from '../context/MainLanguageContext'
import useGet from '../customHooks/useGet'
import usePost from '../customHooks/usePost'

const CaseUpdateModel = ({ data, modelStatus, modelStatusUpdate,updateAp }) => {
    const {case_id} = data
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [resGet, apiMethodGet] = useGet() 
    const [res, apiMethod] = usePost() 
    const [datas, setDatas] = useState("")
    const [caseUpdate, setCaseUpdate] = useState("")

    useEffect(() => {
        apiMethodGet(`caseManagements/singleDetail/${case_id}`);
    }, [])

    useEffect(() => {
        if (resGet.data) {
            setDatas(resGet.data?.data)
        }
    }, [resGet.data])
    
    const handleCloseModel = () => {
        modelStatusUpdate(false)
    }

    const handleSubmit = () => {
        let formdata = new FormData();
        formdata.append("case_id", case_id);
        formdata.append("message", caseUpdate);
        apiMethod(`caseManagements/requestCaseUpdate`,formdata)
    }

    useEffect(() => {
     if(res.data) {
        modelStatusUpdate(false)
        updateAp(case_id)
     }
    }, [res.data])
    


    return (
        <>
            <div onClick={handleCloseModel} className={`backgroundFixed w-full h-screen  fixed top-[50%] left-0 right-0 transform translate-y-[-50%]  bg-black opacity-90 z-10 ${modelStatus ? "block" : "hidden"}`}></div>
            <div className={`CaseUpdateModel transition-all duration-300  bg-white rounded-xl fixed z-20  left-0 right-0 mx-auto transform translate-y-[-50%] w-[50%] ${modelStatus ? "opacity-100 top-[50%] active" : "top-[40rem] opacity-0"}`}>
                <div className='closeButton cursor-pointer absolute bg-[#E0EBFF] w-[2.938rem] h-[2.938rem] top-0 right-[-4rem] grid place-items-center rounded-[.7rem] max-lg:right-[-1rem] max-lg:top-[-2rem] ' onClick={handleCloseModel}>
                    <img src={close} alt="" />
                </div>
                <div className='max-h-[80vh] overflow-auto modelBox'>
                    <div className="bookingBox  bg-[#EFF4FD] p-5 rounded-xl">
                   
                    <div className="h2 text-[1rem] font-Mluvka flex items-center gap-1">
                    <span className="">
                       {datas?.client_name}
                    </span>
                    <span className="bg-primary p-1 text-[1rem] rounded-xl">
                       {datas?.case_number}
                    </span>
                </div>
               <h1 className="mt-2 text-[1.5rem] font-Mluvka">{datas?.case_title}</h1>
               
                <div className="Lawyer my-4 flex items-center gap-2 ">
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">
                        Lawyer
                    </span>
                    <div className="w-full h-[.1rem] bg-[#C9D4E9]"></div>
                </div>

                <div className="dpartCardProfile flex mt-1">    
                {Array.isArray(datas?.teams) && datas?.teams.map((item, index) => {
                                const {lowyer_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={lowyer_image} alt={id} />
                                    </div>
                                )
                            })}
                  
                    </div>
                <div className="Lawyer my-4 flex items-center gap-2 ">
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem] w-[8rem]">
                    Legal Secretary
                    </span>
                    <div className="w-full h-[.1rem] bg-[#C9D4E9]"></div>
                </div>

                <div className="dpartCardProfile flex mt-1">    
                {Array.isArray(datas?.secretaries) && datas?.secretaries.map((item, index) => {
                                const {legal_secretary_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={legal_secretary_image} alt={id} />
                                    </div>
                                )
                            })}
                  
                    </div>

                    <br />

                    <div className="h4 font-MluvkaBold mb-2">Case Update</div>
                     <textarea onChange={(e) => setCaseUpdate(e.target.value)} placeholder='Case Update' className='w-full border border-[#ddd] rounded-xl h-[5rem] p-2 outline-none' name="" id=""></textarea>
                
                     <button  onClick={handleSubmit} className='font-Mluvka w-fit mt-4 ml-auto block py-3 px-5 uppercase text-white bg-secondary rounded-full'>{res.isLoading ? "loading" : "Submit"}</button>


                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseUpdateModel
