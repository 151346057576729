import React, { useState } from 'react'
import { webContentTab } from '../data/data';
import Home from './Home/Home';
import AboutUs from './About/AboutUs';
import ContactUs from './Contact/ContactUs';
import OtherPage from '../OtherPage/OtherPage';
import News from './News/News';
import Events from './Events/Events';
import Services from './Services/Services';
import Laws from './Laws/Laws';
import WebFaqs from './Faqs/WebFaqs';
import Team from './Team/Team';

const WebContent = () => {
    const TabList = {
        "Home": <Home />,
        "Team": <Team />,
        "About Us": <AboutUs />,
        "Contact Us": <ContactUs />,
        "News": <News />,
        "Event": <Events />,
        "Services": <Services />,
        "Faqs": <WebFaqs />,
        "Laws": <Laws />,
        "Other Page": <OtherPage />,
    };
    const [tabIndex, setTabIndex] = useState("Home");
    const handleWebtabUpdate = (label) => {
        setTabIndex(label)
    }
  return (
    <div className='webContentPage'>
         <div className="bookingPagetop flex justify-between items-center mb-6">
                <div className="bookingPage-left flex items-center">

                    <ul className='list flex gap-6 mb-4 flex-wrap'>
                        {
                            webContentTab.map((item, index) => {
                                const { label } = item
                                return (
                                    <li key={index} className={`font-MluvkaBold border-l-2 border-secondary leading-[1] pl-2 cursor-pointer transition-all duration-300 ease-in-out   ${tabIndex === label ? "text-[#000] active" : "text-[#999]"}`}  onClick={() => handleWebtabUpdate(label)}>{label}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="bookingPageBody">
                    {TabList[tabIndex]}
            </div>
    </div>
  )
}

export default WebContent