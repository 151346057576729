import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import SubmitButton from '../components/SubmitButton'
import SkeletonPrivacy from './SkeletonPrivacy'
import { MainLanguageContext } from '../context/MainLanguageContext'
import useGet from '../customHooks/useGet'
import usePost from '../customHooks/usePost'
import { toast } from 'react-toastify'
import swal from "sweetalert";
import CKEditors from '../components/form/CKEditors'

const Terms = () => {
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [loading, setLoading] = useState(true)
    const [datas, setDatas] = useState({
        "description": ""
    })
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
            setLoading(true);
            apiMethodGet(`appcontent/term-condition`);
        }
    }, [mainLanguage]);
    const { description } = datas;

    useEffect(() => {
        if(!resget.isLoading) {
            setLoading(false)
            setDatas((prevData) => ({...prevData,"description":resget?.data?.data?.description}))
        }
       }, [resget.data])
   
       const [res, apiMethod] = usePost()
       const requireFeild = [
        "heading",
    ];
    const handledescriptionChange = (e) => {
        setDatas(d => ({ ...d, "description": e }));
    };

    const handleSubmit = (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
            heading: "heading",
          };
          let checkerRequried = [];
        for (const item in values) {
            if (requireFeild.includes(item) && values[item] === "") {
              checkerRequried.push(requireFeildSwal[item]);
            }
            formdata.append(item, values[item]);
        }
        
        formdata.append("description", description);
          if(!description) {
            checkerRequried.push("description")
          }

          if (checkerRequried.length > 0) {
            swal({
                title: "Required Fields are empty! Please fill and try again",
                text: checkerRequried.join(","),
                icon: "error",
                dangerMode: true,
            });
        }

        else {
          apiMethod(`appcontent/term-condition`, formdata)
        }
    }

    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            toast.success(message);
          }
        }
      }, [res.data])


    if (resget.isLoading) return <SkeletonPrivacy />
    const initialValues = {
        heading: resget?.data?.data?.heading,
    }
    return (
        <div className='PrivicyPolicy pr-10'>
        <Formik initialValues={initialValues}  onSubmit={handleSubmit}  >
            <Form>
                <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-8'>
                    <FormControl name="heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                    <br />
                    <label htmlFor="description" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Description</label>
                    <CKEditors label={"description"} data={description} update={handledescriptionChange} />
                </div>
                {(
                    <SubmitButton
                        props={{
                            class: "btn bg-secondary text-white uppercase py-3 px-10 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                            text: "Update",
                        }}
                        buttonLoading={res.isLoading}
                    />
                )}
                <br />
            </Form>
        </Formik>
    </div>
    )
}

export default Terms