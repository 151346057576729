import React, { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { removeTokenSession } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import config from "../services/config.json";
import usePost from '../customHooks/usePost';
import { toast } from 'react-toastify';

const HeaderProfile = ({profile_image}) => {
    const navigate = useNavigate();
    const [res, apiMethod] = usePost()
    const handleLogOut = () => {
        let formdata = new FormData();
        apiMethod("logout",formdata)
    };
    useEffect(() => {
        if(res.data) {
          if(res.data.status === "false") {
            toast.error(res.data.message);
          }
          else {
            toast.success(res.data.message);
            removeTokenSession();
            navigate(`/${config.demo}login`);
          }
        }
      }, [res.data])

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <button className="px-6 font-Mluvka" onClick={handleLogOut}>
                    Logout
                </button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
                <div className="profile__Box relative">
                    <div className="profile__Box__img cursor-pointer">
                        <img
                            src={profile_image}
                            className="w-[4rem] h-[4rem] max-lg:w-[3rem] max-lg:h-[3rem] rounded-full p-2 object-cover border border-[#CFD5E2]"
                            alt="Profile"
                        />
                    </div>
                </div>
            </a>
        </Dropdown>
    );
};

export default HeaderProfile;
