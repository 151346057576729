import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonGallery = () => {
  return (
<SkeletonTheme baseColor="#fff" highlightColor="#f0f0f0">
      <div className='GalleryPage pr-10'>
      <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
        <Skeleton height={48} />
        <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 mt-4'>
          <Skeleton height={160} />
          <Skeleton height={160} />
        </div>
      </div>
        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
          <div className="h4 text-[#7D8CA7] text-[1.1rem] mb-4">
            <Skeleton width="30%" />
          </div>
          <div className="mb-4">
            <Skeleton height={48} />
          </div>
        </div>
        <div className='bg-[#EFF4FD] galleryMain p-6 rounded-3xl mb-3'>
          <div className='grid grid-cols-4 gap-4 max-lg:grid-cols-2'>
            {[...Array(8)].map((_, index) => (
              <div key={index}>
                <Skeleton height={200} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default SkeletonGallery;
