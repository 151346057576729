import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLegalSecretaryCard = ({page}) => {
  return (
    <SkeletonTheme baseColor="#ddd" highlightColor="#eee">
      <div className='lawyerCard relative bg-[#fff] border border-[#D4DEF1] rounded-3xl shadow-custom p-4'>
        <div className='cursor-pointer relative'>
          <div className="lawyerCard_ flex items-center gap-4">
            <div className="lawyerCard__img">
              <Skeleton width="3.5rem" height="3.5rem" className='rounded-sm' />
            </div>
            <div className="lawyerCard__txt">
              <div className="h2 text-[1.25rem] font-Mluvka leading-[1]">
                <Skeleton width={100} />
              </div>
              <p className='leading-[1] mt-2 text-primary text-[.8rem]'>
                <Skeleton width={150} />
              </p>
            </div>
          </div>
          {page !== "home" && (
            <>
              <div className='my-4 border border-primary w-fit py-3 px-6 rounded-2xl flex items-center gap-2'>
                <Skeleton width={20} height={20} />
                <span className='font-Mluvka'>
                  <Skeleton width={80} />
                </span>
              </div>
              <p className='text-[.9rem] text-[#3C3E56] mb-4'>
                <Skeleton count={3} />
              </p>
              <div className="h3 font-MluvkaBold text-[#120D26]">
                <Skeleton width={100} />
              </div>
              <div className='lawyerCardbutton mt-4 flex flex-wrap gap-2'>
                {[...Array(5)].map((_, index) => (
                  <div className='text-[.8rem] w-[45%] bg-primary text-white py-3 px-6 rounded-2xl' key={index}>
                    <Skeleton width={"100%"} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default SkeletonLegalSecretaryCard