import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import CKEditors from '../components/form/CKEditors'
import SubmitButton from '../components/SubmitButton'
import SkeletonPrivacyPolicy from './SkeletonPrivicyPolicy'
import { MainLanguageContext } from '../context/MainLanguageContext'
import useGet from '../customHooks/useGet'
import usePost from '../customHooks/usePost'
import { toast } from 'react-toastify'
import swal from "sweetalert";

const Expertise = () => {
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [loading, setLoading] = useState(true)
    const [datas, setDatas] = useState({
        "description": ""
    })
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
            setLoading(true);
            apiMethodGet(`webContents/othertab/expertise/${mainLanguage}`);
        }
    }, [mainLanguage]);
    const { description } = datas;

    useEffect(() => {
        if(!resget.isLoading) {
            setLoading(false)
            setDatas((prevData) => ({...prevData,"description":resget?.data?.data?.description}))
        }
       }, [resget.data])
   
       const [res, apiMethod] = usePost()
       const requireFeild = ["meta_tag","meta_description", "heading",
    ];
    const handledescriptionChange = (e) => {
        setDatas(d => ({ ...d, "description": e }));
    };

    const handleSubmit = (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
            meta_tag:"Meta Title",
            meta_description:"Meta Description",
            heading: "heading",
          };
          let checkerRequried = [];
        for (const item in values) {
            if (requireFeild.includes(item) && values[item] === "") {
              checkerRequried.push(requireFeildSwal[item]);
            }
            formdata.append(item, values[item]);
        }
        
        formdata.append("description", description);
          if(!description) {
            checkerRequried.push("description")
          }

          if (checkerRequried.length > 0) {
            swal({
                title: "Required Fields are empty! Please fill and try again",
                text: checkerRequried.join(","),
                icon: "error",
                dangerMode: true,
            });
        }

        else {
          apiMethod(`webContents/othertab/expertise/${mainLanguage}`, formdata)
        }
    }

    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            toast.success(message);
          }
        }
      }, [res.data])


    if (resget.isLoading) return <SkeletonPrivacyPolicy />
    const initialValues = {
        meta_tag: resget?.data?.data?.meta_tag ?? "",
        meta_description: resget?.data?.data?.meta_description ?? "",
        schema_code: resget?.data?.data?.schema_code ?? "",
        heading: resget?.data?.data?.heading,
    }

    return (
        <div className='Expertise'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
                <Form>
                <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-8'>
                <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                        <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
                            <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                            <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                        </div>
                        </div>

                        <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-8'>
                            <FormControl name="heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            <br />
                            <label htmlFor="description" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Description</label>
                            <CKEditors label={"description"} data={description} update={handledescriptionChange} />
                        </div>
                        {(
                                    <SubmitButton
                                        props={{
                                            class: "btn bg-secondary text-white uppercase py-3 px-10 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                                            text: "Update",
                                        }}
                                        buttonLoading={res.isLoading}
                                    />
                                )}
                                <br />
                </Form>
            </Formik>
        </div>
    )
}

export default Expertise