import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import UploadProduct from '../components/UploadProduct'
import SkeletonGallery from './SkeletonGallery'
import UpdateButton from '../components/UpdateButton'
import { MainLanguageContext } from '../context/MainLanguageContext'
import useGet from '../customHooks/useGet'
import usePost from '../customHooks/usePost'
import swal from "sweetalert";
import { toast } from 'react-toastify'

const Gallery = () => {
    const [loading, setLoading] = useState(true)
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [datas, setDatas] = useState()
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
          setLoading(true);
          apiMethodGet(`webContents/gallery/${mainLanguage}`);
        }
      }, [mainLanguage]);

    const [fileList, setFileList] = useState([
    ]);
    useEffect(() => {
      setLoading(false)
        if(resget?.data) {
          setDatas(resget?.data?.data)
          const {gallery_images} = resget?.data?.data
          let attention = []
          let att = gallery_images
          if(att?.length> 0) {
              for (let index = 0; index < att.length; index++) {  
                  attention.push({
                      uid: index,
                      name: `Images ${index}`,
                      status: 'done',
                      url: `${att[index].image}`,
                      old_image: `${att[index].old_images}`,
                    })
                }
        }
          setFileList(attention)
        }
      }, [resget.isLoading])
    const hanldefileUpdate = (values) => {
        setFileList(values)
    }
    const [res, apiMethod] = usePost();
    const requireFeild = ["meta_tags","meta_description","header"];
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
          meta_tags: "Meta Tag",
          meta_description: "Meta Description",
          header: "Heading",
        };
        let checkerRequried = [];
        for (const item in values) {
          if (requireFeild.includes(item) && values[item] === "") {
            checkerRequried.push(requireFeildSwal[item]);
          }
          formdata.append(item, values[item]);
        }
        if (checkerRequried.length > 0) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: checkerRequried.join(","),
              icon: "error",
              dangerMode: true,
            });
          }
          else {
            console.log(fileList)
            for (let index = 0; index < fileList.length; index++) {
                formdata.append("images[]", fileList[index]?.originFileObj ?? "");
                formdata.append("old_images[]", fileList[index]?.old_image ?? "");
              }
            apiMethod(`webContents/gallery/${mainLanguage}`, formdata)
          }
    }
    useEffect(() => {
      if (res.data) {
        const { status, message } = res?.data
        if (status === "false") {
          toast.error(message);
        }
        else {
          toast.success(message);
        }
      }
    }, [res.data])
    if (resget.isLoading || loading || !datas) return <SkeletonGallery />
    const {schema_code,meta_tag,meta_description,header} = resget?.data?.data
    const initialValues = {
        meta_tag: meta_tag,
        meta_description: meta_description,
        schema_code: schema_code,
        header: header,
    }
    return (
        <div className='GalleryPage  pr-10 max-lg:pr-6'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
                <Form>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                    <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
                    <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                    <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                    </div>
                    </div>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <FormControl name="header" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                    </div>

                    <div className='bg-[#EFF4FD] galleryMain p-6 rounded-3xl mb-3'>
                        <UploadProduct updatess={(item) => hanldefileUpdate(item)} filelis={fileList} />
                    </div>
                    <UpdateButton loading={res.isLoading} className='btn bg-secondary text-white px-16 uppercase   py-3 rounded-full w-100 block my-5 ml-auto submit hover:bg-primary transition-all duration-300' label={"Update"} />
                </Form>
            </Formik>
        </div>
    )
}

export default Gallery
